import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    valuation: {},
  },
  getters: {
    getValuation(state) {
      return state.valuation
    },
  },
  mutations: {
    SET_VALUATION(state, data) {
      state.valuation = data
    },
  },
  actions: {
    valuation({ commit }, id) {
      axiosIns
        .post('/dealers/reports/valuation', { id_startups: id })
        .then(response => {
          commit('SET_VALUATION', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
