import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    adminDashboardData: {
      startups: 0,
      investors: 0,
      entrepreneurs: 0,
      latest_startups: [],
      latest_blog_posts: [],
      pending_orders: [],
      latest_orders: [],
      pending_startups: [],
      pending_startups_count: 0,
      approved_startups_count: 0,
    },
  },
  getters: {
    getAdminDashboardData(state) {
      return state.adminDashboardData
    },
  },
  mutations: {
    ADMIN_DASHBOARD_DATA_LIST(state, data) {
      state.adminDashboardData = data
    },
  },
  actions: {
    adminDashboardDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/dashboard', data)
        .then(response => {
          commit('ADMIN_DASHBOARD_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
