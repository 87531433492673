export default [
  {
    path: '/admin/investors',
    name: 'InvestorsAdmin',
    component: () => import('@/views/Admin/Investors/Index.vue'),
    meta: {
      pageTitle: 'Yatırımcılar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_INVESTORS',
      action: 'read',
    },
  },
  {
    path: '/admin/investors/view/:id',
    name: 'InvestorsAdminView',
    component: () => import('@/views/Admin/Investors/View.vue'),
    meta: {
      pageTitle: 'Yatırımcılar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/investors',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_INVESTORS',
      action: 'read',
    },
  },
]
