export default [
  {
    path: '/raporlar/girisim-raporu/:id',
    name: 'StartupReport',
    component: () => import('@/views/App/Reports/Startup_report.vue'),
    meta: {
      pageTitle: 'Girişim Raporu',
      breadcrumb: [
        {
          text: 'Girişim Raporu',
          active: true,
        },
      ],
      resource: 'STARTUP_REPORT',
      action: 'read',
    },
  },
  {
    path: '/raporlar/pazar-analizi-raporu/:id',
    name: 'MarketAnalysis',
    component: () => import('@/views/App/Reports/Market_analysis.vue'),
    meta: {
      pageTitle: 'Pazar Analizi Raporu',
      breadcrumb: [
        {
          text: 'Pazar Analizi Raporu',
          active: true,
        },
      ],
      resource: 'MARKET_ANALYSIS_REPORT',
      action: 'read',
    },
  },
  {
    path: '/raporlar/is-modeli-performansi/:id',
    name: 'BusinessModelAnalysis',
    component: () => import('@/views/App/Reports/Business_model_analysis.vue'),
    meta: {
      pageTitle: 'İş Modeli Performansı',
      breadcrumb: [
        {
          text: 'İş Modeli Performansı',
          active: true,
        },
      ],
      resource: 'BUSINESS_MODEL_ANALYSIS_REPORT',
      action: 'read',
    },
  },
  {
    path: '/raporlar/is-modeli-kanvasi/:id',
    name: 'Canva',
    component: () => import('@/views/App/Reports/Canva.vue'),
    meta: {
      pageTitle: 'İş Modeli Kanvası',
      breadcrumb: [
        {
          text: 'İş Modeli Kanvası',
          active: true,
        },
      ],
      resource: 'CANVA_REPORT',
      action: 'read',
    },
  },
  {
    path: '/raporlar/teknoloji-hazirlik-seviyesi/:id',
    name: 'TechnologyReadiness',
    component: () => import('@/views/App/Reports/Technology_readiness_level.vue'),
    meta: {
      pageTitle: 'THS',
      breadcrumb: [
        {
          text: 'Teknoloji Hazırlık Seviyesi',
          active: true,
        },
      ],
      resource: 'TRL_REPORT',
      action: 'read',
    },
  },
  {
    path: '/raporlar/degerleme/:id',
    name: 'Valuation',
    component: () => import('@/views/App/Reports/Valuation.vue'),
    meta: {
      pageTitle: 'Değerleme',
      breadcrumb: [
        {
          text: 'Değerleme',
          active: true,
        },
      ],
      resource: 'VALUATION_REPORT',
      action: 'read',
    },
  },
  {
    path: '/raporlar/etki-analizi/:id',
    name: 'Sdg',
    component: () => import('@/views/App/Reports/Sdg.vue'),
    meta: {
      pageTitle: 'Etki Analizi',
      breadcrumb: [
        {
          text: 'Etki Analizi',
          active: true,
        },
      ],
      resource: 'SDG_REPORT',
      action: 'read',
    },
  },
  {
    path: '/raporlar/yatirimci-ozet-sayfasi/:id',
    name: 'InvestorSummaryReport',
    component: () => import('@/views/App/Reports/Investor_summary_report.vue'),
    meta: {
      pageTitle: 'Yatırımcı Özet Sayfası',
      breadcrumb: [
        {
          text: 'Yatırımcı Özet Sayfası',
          active: true,
        },
      ],
      resource: 'INVESTOR_SUMMARY_REPORT',
      action: 'read',
    },
  },
]
