import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import app from './routes/app'
import core from './routes/core'
import startups from './app/startups'
import investors from './app/investors'
import faqApp from './app/faq'
import profile from './app/profile'
import favorites from './app/interests/favorites'
import following from './app/interests/following'
import portfolio from './app/interests/portfolio'
import requests from './app/interests/requests'
import newStartups from './app/new_startups'
import investorProfile from './app/investor_profile'
import appOrders from './app/orders'
/* Admin Routes */
import startupStatuses from './admin/defines/startup_statuses'
import activityAreas from './admin/defines/activity_areas'
import marketingFocus from './admin/defines/marketing_focus'
import startupConfigs from './admin/defines/startup_configs'
import faqCategories from './admin/faq_categories'
import faq from './admin/faq'
import packages from './admin/packages'
import orders from './admin/orders'
import terms from './admin/defines/terms'
import startupAppliesAdmin from './admin/startup_applies'
import users from './admin/users'
import userTypes from './admin/user_types'
import bankAccounts from './admin/bank_accounts'
import adminInvestors from './admin/investors'
import adminDealers from './admin/dealers'
import adminDealerOrders from './admin/dealer_orders'
/* Reports */
import reports from './app/reports/reports'
/* Dealers */
import dealerPackages from './dealers/packages'
import dealerStartups from './dealers/startup_applies'
import dealerAccount from './dealers/account'
import dealerReports from './dealers/reports'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...app,
    ...core,
    ...startups,
    ...investors,
    ...faq,
    ...faqApp,
    ...favorites,
    ...following,
    ...portfolio,
    ...requests,
    ...newStartups,
    ...investorProfile,
    // Admin Routes
    ...startupStatuses,
    ...startupAppliesAdmin,
    ...activityAreas,
    ...marketingFocus,
    ...startupConfigs,
    ...faqCategories,
    ...terms,
    ...packages,
    ...orders,
    ...profile,
    ...reports,
    ...users,
    ...userTypes,
    ...appOrders,
    ...bankAccounts,
    ...adminInvestors,
    ...adminDealers,
    ...adminDealerOrders,
    ...dealerPackages,
    ...dealerStartups,
    ...dealerAccount,
    ...dealerReports,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
