import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: 0,
    dataItem: {},
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCount(state) {
      return state.dataCount
    },
    dataItem(state) {
      return state.dataItem
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/defines/startup_reports', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getData({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'startup_reports.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/defines/startup_reports', data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
