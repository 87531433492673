export default [
  {
    path: '/yatirimcilar',
    name: 'Investors',
    component: () => import('@/views/App/Investors/Index.vue'),
    meta: {
      pageTitle: 'Yatırımcılar',
      breadcrumb: [
        {
          text: 'Keşfet',
          active: true,
        },
      ],
      resource: 'APP_INVESTORS',
      action: 'read',
    },
  },
]
