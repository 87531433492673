import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dashboardData: {
      startups: 0,
      investors: 0,
      entrepreneurs: 0,
      latest_startups: [],
      latest_blog_posts: [],
      pending_startups: [],
      pending_startups_count: 0,
      approved_startups_count: 0,
    },
  },
  getters: {
    dashboardData(state) {
      return state.dashboardData
    },
  },
  mutations: {
    SET_DASHBOARD(state, data) {
      state.dashboardData = data
    },
  },
  actions: {
    getDashboardData({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dealers/dashboard', data)
        .then(response => {
          commit('SET_DASHBOARD', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
