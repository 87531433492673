import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    userAgreement: {
      title: null,
      content: null,
    },
    privacyPolicy: {
      title: null,
      content: null,
    },
  },
  getters: {
    getUserAgreement(state) {
      return state.userAgreement
    },
    getPrivacyPolicy(state) {
      return state.privacyPolicy
    },
  },
  mutations: {
    USER_AGREEMENT(state, data) {
      state.userAgreement = data
    },
    PRIVACY_POLICY(state, data) {
      state.privacyPolicy = data
    },
  },
  actions: {
    terms({ commit }) {
      axiosIns
        .post('/public/terms')
        .then(response => {
          commit('USER_AGREEMENT', response.data.userAgreement)
          commit('PRIVACY_POLICY', response.data.privacyPolicy)
        })
        .catch(error => console.error(error))
    },
  },
}
