import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    bankAccounts: [],
    bankAccountsCount: 0,
    bankAccount: {},
    bankAccountSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getBankAccounts(state) {
      return state.bankAccounts
    },
    getBankAccountsCount(state) {
      return state.bankAccountsCount
    },
    getBankAccount(state) {
      return state.bankAccount
    },
    getBankAccountSaveStatus(state) {
      return state.bankAccountSave
    },
  },
  mutations: {
    SET_BANK_ACCOUNTS(state, data) {
      state.bankAccounts = data
    },
    SET_BANK_ACCOUNT(state, data) {
      state.bankAccount = data
    },
    SET_BANK_ACCOUNTS_COUNT(state, data) {
      state.bankAccountsCount = data
    },
    SET_BANK_ACCOUNT_SAVE(state, data) {
      state.bankAccountSave = data
    },
  },
  actions: {
    bankAccountsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/bank_accounts', data)
        .then(response => {
          commit('SET_BANK_ACCOUNTS', response.data.data)
          commit('SET_BANK_ACCOUNTS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    bankAccountView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'bank_accounts.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/bank_accounts', data)
        .then(response => {
          commit('SET_BANK_ACCOUNT', response.data.data)
        })
        .catch(error => console.error(error))
    },
    bankAccountSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/bank_accounts/saveData', data)
        .then(response => {
          commit('SET_BANK_ACCOUNT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    bankAccountRemove({ commit }, id) {
      axiosIns
        .post('/admin/bank_accounts/deleteData', { id })
        .then(response => {
          commit('SET_BANK_ACCOUNT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
