import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    startupConfigs: [],
    startupConfigsCount: 0,
    startupConfig: {},
    startupConfigSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getStartupConfigs(state) {
      return state.startupConfigs
    },
    getStartupConfigsCount(state) {
      return state.startupConfigsCount
    },
    getStartupConfig(state) {
      return state.startupConfig
    },
  },
  mutations: {
    STARTUP_CONFIGS_LIST(state, data) {
      state.startupConfigs = data
    },
    STARTUP_CONFIG_VIEW(state, data) {
      state.startupConfig = data
    },
    STARTUP_CONFIGS_COUNT(state, data) {
      state.startupConfigsCount = data
    },
  },
  actions: {
    startupConfigsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/startup_configs', data)
        .then(response => {
          commit('STARTUP_CONFIGS_LIST', response.data.data)
          commit('STARTUP_CONFIGS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    startupConfigView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'startup_configs.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/startup_configs', data)
        .then(response => {
          commit('STARTUP_CONFIG_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
