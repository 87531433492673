import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    packages: [],
    packagesCount: 0,
    package: {},
    packageSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getPackages(state) {
      return state.packages
    },
    getPackagesCount(state) {
      return state.packagesCount
    },
    getPackage(state) {
      return state.package
    },
    getPackagesSaveStatus(state) {
      return state.packageSave
    },
  },
  mutations: {
    PACKAGES_LIST(state, data) {
      state.packages = data
    },
    PACKAGE_VIEW(state, data) {
      state.package = data
    },
    PACKAGES_COUNT(state, data) {
      state.packagesCount = data
    },
    PACKAGE_SAVE(state, data) {
      state.packageSave = data
    },
  },
  actions: {
    packagesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/packages', data)
        .then(response => {
          commit('PACKAGES_LIST', response.data.data)
          commit('PACKAGES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    packageView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'packages.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/packages', data)
        .then(response => {
          commit('PACKAGE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    packageSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/packages/saveData', data)
        .then(response => {
          commit('PACKAGE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
