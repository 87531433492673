export default [
  {
    path: '/admin/sss-kategorileri',
    name: 'FaqCategories',
    component: () => import('@/views/Admin/Faq_categories/Index.vue'),
    meta: {
      pageTitle: 'SSS Kategorileri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/sss-kategorileri/ekle',
    name: 'FaqCategoriesAdd',
    component: () => import('@/views/Admin/Faq_categories/Add.vue'),
    meta: {
      pageTitle: 'SSS Kategorileri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/sss-kategorileri',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/sss-kategorileri/guncelle/:id',
    name: 'FaqCategoriesEdit',
    component: () => import('@/views/Admin/Faq_categories/Edit.vue'),
    meta: {
      pageTitle: 'SSS Kategorileri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/sss-kategorileri',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
