import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    investorProfiles: [],
    investorProfilesCount: 0,
    investorProfileForm: [],
    investorProfileResult: {},
    investorProfileSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getInvestorProfile(state) {
      return state.investorProfiles
    },
    getInvestorProfileCount(state) {
      return state.investorProfilesCount
    },
    getInvestorProfileForm(state) {
      return state.investorProfileForm
    },
    getInvestorProfileResult(state) {
      return state.investorProfileResult
    },
    getInvestorProfileSaveStatus(state) {
      return state.investorProfileSave
    },
  },
  mutations: {
    INVESTOR_PROFILE_LIST(state, data) {
      state.investorProfiles = data
    },
    INVESTORS_PROFILE_COUNT(state, data) {
      state.investorProfilesCount = data
    },
    INVESTOR_PROFILE_FORM(state, data) {
      state.investorProfileForm = data
    },
    INVESTOR_PROFILE_RESULT(state, data) {
      state.investorProfileResult = data
    },
    INVESTOR_PROFILE_SAVE(state, data) {
      state.investorProfileSave = data
    },
  },
  actions: {
    investorProfileList({ commit }) {
      axiosIns
        .post('/data/investor_profile')
        .then(response => {
          commit('INVESTOR_PROFILE_LIST', response.data.data)
          commit('INVESTORS_PROFILE_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    investorProfileCreate({ commit }) {
      axiosIns
        .post('/data/investor_profile/create')
        .then(response => {
          commit('INVESTOR_PROFILE_FORM', response.data.data)
          commit('INVESTOR_PROFILE_RESULT', response.data.result)
        })
        .catch(error => console.error(error))
    },
    investorProfileEdit({ commit }, id) {
      axiosIns
        .post('/data/investor_profile/edit', { id })
        .then(response => {
          commit('INVESTOR_PROFILE_FORM', response.data.data)
          commit('INVESTOR_PROFILE_RESULT', response.data.result)
        })
        .catch(error => console.error(error))
    },
    investorProfileSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/investor_profile/saveData', data)
        .then(response => {
          commit('INVESTOR_PROFILE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
