import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    summary: [],
    detail: [],
    radarData: {
      data: [],
      categories: [],
    },
    radialBarData: {
      series: [],
      labels: [],
    },
    donutData: {
      series: [],
      labels: [],
    },
  },
  getters: {
    summary(state) {
      return state.summary
    },
    detail(state) {
      return state.detail
    },
    radarData(state) {
      return state.radarData
    },
    radialBarData(state) {
      return state.radialBarData
    },
    donutData(state) {
      return state.donutData
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.summary = data.summary
      state.detail = data.detail
      state.radarData = data.chartData.radarData
      state.radialBarData = data.chartData.radialBarChart
      state.donutData = data.chartData.donutChart
    },
  },
  actions: {
    getData({ commit }, id) {
      axiosIns
        .post('/admin/startup_requests/sdg', { id })
        .then(response => {
          commit('SET_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
