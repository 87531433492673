import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketAnalysis: [],
  },
  getters: {
    getMarketAnalysisList(state) {
      return state.marketAnalysis
    },
  },
  mutations: {
    MARKET_ANALYSIS_LIST(state, data) {
      state.marketAnalysis = data
    },
  },
  actions: {
    marketAnalysisList({ commit }, idStartups) {
      axiosIns
        .post('/admin/startup_requests/marketAnalysis', { id_startups: idStartups })
        .then(response => {
          commit('MARKET_ANALYSIS_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
