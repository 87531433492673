export default [
  {
    path: '/admin/tanimlamalar/girisimci-ayarlari',
    name: 'StartupConfigs',
    component: () => import('@/views/Admin/Defines/Startup_configs/Index.vue'),
    meta: {
      pageTitle: 'Girişimci Ayarları',
      breadcrumb: [
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/tanimlamalar/girisimci-ayarlari/ekle',
    name: 'StartupConfigsAdd',
    component: () => import('@/views/Admin/Defines/Startup_configs/Add.vue'),
    meta: {
      pageTitle: 'Girişimci Ayarları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tanimlamalar/girisimci-ayarlari',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/tanimlamalar/girisimci-ayarlari/guncelle/:id',
    name: 'StartupConfigsEdit',
    component: () => import('@/views/Admin/Defines/Startup_configs/Edit.vue'),
    meta: {
      pageTitle: 'Girişimci Ayarları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tanimlamalar/girisimci-ayarlari',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
