export default [
  {
    path: '/dealers/packages',
    name: 'DealerPackages',
    component: () => import('@/views/Dealers/Packages/Index.vue'),
    meta: {
      pageTitle: 'Paketler',
      breadcrumb: [
        {
          text: 'Paketler',
          active: true,
        },
      ],
      resource: 'DEALER_PACKAGES',
      action: 'read',
    },
  },
  {
    path: '/dealers/packages/view/:id',
    name: 'DealerPackageView',
    component: () => import('@/views/Dealers/Packages/View.vue'),
    meta: {
      pageTitle: 'Paketler',
      breadcrumb: [
        {
          text: 'Paketler',
          to: '/dealers/packages',
        },
        {
          text: 'Satın Al',
          active: true,
        },
      ],
      resource: 'DEALER_PACKAGES',
      action: 'read',
    },
  },
]
