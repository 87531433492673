export default [
  {
    path: '/yatirimci-profili',
    name: 'InvestorProfile',
    component: () => import('@/views/App/InvestorProfile/Index.vue'),
    meta: {
      pageTitle: 'Yatırımcı Profili',
      breadcrumb: [
        {
          text: 'Şirketlerim',
          active: true,
        },
      ],
      resource: 'APP_INVESTOR_PROFILE',
      action: 'read',
    },
  },
  {
    path: '/yatirimci-profili/yeni-olustur',
    name: 'InvestorProfileAdd',
    component: () => import('@/views/App/InvestorProfile/Add.vue'),
    meta: {
      pageTitle: 'Yatırımcı Profili',
      breadcrumb: [
        {
          text: 'Şirketlerim',
          to: '/yatirimci-profili',
          active: false,
        },
        {
          text: 'Yeni Oluştur',
          active: true,
        },
      ],
      resource: 'APP_INVESTOR_PROFILE',
      action: 'read',
    },
  },
  {
    path: '/yatirimci-profili/guncelle/:id',
    name: 'InvestorProfileEdit',
    component: () => import('@/views/App/InvestorProfile/Edit.vue'),
    meta: {
      pageTitle: 'Yatırımcı Profili',
      breadcrumb: [
        {
          text: 'Şirketlerim',
          to: '/yatirimci-profili',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'APP_INVESTOR_PROFILE',
      action: 'read',
    },
  },
]
