import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    portfolios: [],
    portfoliosCount: 0,
    portfolio: {},
  },
  getters: {
    getPortfolios(state) {
      return state.portfolios
    },
    getPortfoliosCount(state) {
      return state.portfoliosCount
    },
    getPortfolio(state) {
      return state.portfolio
    },
  },
  mutations: {
    PORTFOLIOS_LIST(state, data) {
      state.portfolios = data
    },
    PORTFOLIOS_VIEW(state, data) {
      state.portfolio = data
    },
    PORTFOLIOS_COUNT(state, data) {
      state.portfoliosCount = data
    },
  },
  actions: {
    portfoliosList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/portfolio', data)
        .then(response => {
          commit('PORTFOLIOS_LIST', response.data.data)
          commit('PORTFOLIOS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    portfolioView({ commit }, seourl) {
      axiosIns
        .post('/data/portfolio/view', { seourl })
        .then(response => {
          commit('PORTFOLIOS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
