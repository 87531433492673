export default [
  {
    path: '/admin/startup-applications',
    name: 'AdminStartupApps',
    component: () => import('@/views/Admin/Startup_applies/Index.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          active: true,
        },
      ],
      resource: 'ADMIN_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/admin/startup-applications/view/:id',
    name: 'AdminStartupAppView',
    component: () => import('@/views/Admin/Startup_applies/View.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/admin/startup-applications',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/admin/startup-applications/pre-application/:id',
    name: 'AdminStartupPreApp',
    component: () => import('@/views/Admin/Startup_applies/PreApplicationView.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/admin/startup-applications',
          active: false,
        },
        {
          text: 'Ön Başvuru',
          active: true,
        },
      ],
      resource: 'ADMIN_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/admin/startup-applications/market-analysis/:id',
    name: 'AdminStartupMarketAnalysis',
    component: () => import('@/views/Admin/Startup_applies/MarketAnalysisView.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/admin/startup-applications',
          active: false,
        },
        {
          text: 'Pazar Analizi',
          active: true,
        },
      ],
      resource: 'ADMIN_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/admin/startup-applications/business-model/:id',
    name: 'AdminStartupBusinessModel',
    component: () => import('@/views/Admin/Startup_applies/BusinessModelView.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/admin/startup-applications',
          active: false,
        },
        {
          text: 'İş Modeli Analizi',
          active: true,
        },
      ],
      resource: 'ADMIN_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/admin/startup-applications/technology-readiness/:id',
    name: 'AdminStartupTechnologyReadiness',
    component: () => import('@/views/Admin/Startup_applies/TechnologyReadiness.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/admin/startup-applications',
          active: false,
        },
        {
          text: 'Teknoloji Hazırlık Seviyesi',
          active: true,
        },
      ],
      resource: 'ADMIN_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/admin/startup-applications/financial-planning/:id',
    name: 'AdminStartupFinancialPlanning',
    component: () => import('@/views/Admin/Startup_applies/FinancialPlanning.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/admin/startup-applications',
          active: false,
        },
        {
          text: 'Finansal Planlama',
          active: true,
        },
      ],
      resource: 'ADMIN_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/admin/startup-applications/sgd/:id',
    name: 'AdminStartupSgd',
    component: () => import('@/views/Admin/Startup_applies/Sgd.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/admin/startup-applications',
          active: false,
        },
        {
          text: 'Etki Analizi',
          active: true,
        },
      ],
      resource: 'ADMIN_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
]
