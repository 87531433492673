import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: 0,
    dataItem: {
      id: null,
      onum: null,
      subTotal: null,
      taxesTotal: null,
      grandTotal: null,
      tax_rate: null,
      order_status: null,
      member: null,
      dealer_company: null,
      username: null,
      id_dealer_order_status: null,
      id_members: null,
      id_dealers: null,
      id_users: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCount(state) {
      return state.dataCount
    },
    dataItem(state) {
      return state.dataItem
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      axiosIns
        .post('/dealers/orders', params)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getData({ commit }, params) {
      axiosIns
        .post('/dealers/orders', params)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
