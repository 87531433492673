import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    sdgQuestions: [],
    sdqResult: [],
    sdgSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    sdgQuestions(state) {
      return state.sdgQuestions
    },
    sdgSaveStatus(state) {
      return state.sdgSave
    },
    sdqResult(state) {
      return state.sdqResult
    },
  },
  mutations: {
    SDG_QUESTIONS_LIST(state, data) {
      state.sdgQuestions = data
    },
    SDG_SAVE(state, data) {
      state.sdgSave = data
    },
    SET_SDG_RESULT(state, data) {
      state.sdqResult = data
    },
  },
  actions: {
    getSdgQuestions({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/sdg', data)
        .then(response => {
          commit('SDG_QUESTIONS_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/sdg/saveData', data)
        .then(response => {
          commit('SDG_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    saveDraft({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/sdg/saveData?is_draft=1', data)
        .then(response => {
          commit('SDG_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    setSDGResult({ commit }, data) {
      commit('SET_SDG_RESULT', data)
    },
  },
}
