import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    businessModelAnalysis: [
      {
        id: null,
        title: null,
        score: [],
      },
      {
        id: null,
        title: null,
        score: [],
      },
      {
        id: null,
        title: null,
        score: [],
      },
      {
        id: null,
        title: null,
        score: [],
      },
      {
        id: null,
        title: null,
        score: [],
      },
      {
        id: null,
        title: null,
        score: [],
      },
      {
        id: null,
        title: null,
        score: [],
      },
      {
        id: null,
        title: null,
        score: [],
      },
      {
        id: null,
        title: null,
        score: [],
      },
    ],
    canva: {},
  },
  getters: {
    getBusinessModelAnalysis(state) {
      return state.businessModelAnalysis
    },
    getCanva(state) {
      return state.canva
    },
  },
  mutations: {
    BUSINESS_MODEL_ANALYSIS_REPORT(state, data) {
      state.businessModelAnalysis = data
    },
    CANVA_REPORT(state, data) {
      state.canva = data
    },
  },
  actions: {
    businessModelAnalysis({ commit }, id) {
      axiosIns
        .post('/reports/business_model_analysis', { id_startups: id })
        .then(response => {
          commit('BUSINESS_MODEL_ANALYSIS_REPORT', response.data.data)
        })
        .catch(error => console.error(error))
    },
    canva({ commit }, id) {
      axiosIns
        .post('/reports/business_model_analysis/canva', { id_startups: id })
        .then(response => {
          commit('CANVA_REPORT', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
