export default [
  {
    path: '/dealers/reports/startup-report/:id',
    name: 'DealerStartupReport',
    component: () => import('@/views/Dealers/Reports/Startup_report.vue'),
    meta: {
      pageTitle: 'Girişim Raporu',
      breadcrumb: [
        {
          text: 'Girişim Raporu',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_REPORT',
      action: 'read',
    },
  },
  {
    path: '/dealers/reports/market-analysis/:id',
    name: 'DealerMarketAnalysis',
    component: () => import('@/views/Dealers/Reports/Market_analysis.vue'),
    meta: {
      pageTitle: 'Pazar Analizi Raporu',
      breadcrumb: [
        {
          text: 'Pazar Analizi Raporu',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_REPORT',
      action: 'read',
    },
  },
  {
    path: '/dealers/reports/business-model-analysis/:id',
    name: 'DealerBusinessModelAnalysis',
    component: () => import('@/views/Dealers/Reports/Business_model_analysis.vue'),
    meta: {
      pageTitle: 'İş Modeli Performansı',
      breadcrumb: [
        {
          text: 'İş Modeli Performansı',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_REPORT',
      action: 'read',
    },
  },
  {
    path: '/dealers/reports/canva/:id',
    name: 'DealerCanva',
    component: () => import('@/views/Dealers/Reports/Canva.vue'),
    meta: {
      pageTitle: 'İş Modeli Kanvası',
      breadcrumb: [
        {
          text: 'İş Modeli Kanvası',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_REPORT',
      action: 'read',
    },
  },
  {
    path: '/dealers/reports/technology-readiness-level/:id',
    name: 'DealerTechnologyReadiness',
    component: () => import('@/views/Dealers/Reports/Technology_readiness_level.vue'),
    meta: {
      pageTitle: 'THS',
      breadcrumb: [
        {
          text: 'Teknoloji Hazırlık Seviyesi',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_REPORT',
      action: 'read',
    },
  },
  {
    path: '/dealers/reports/valuation/:id',
    name: 'DealerValuation',
    component: () => import('@/views/Dealers/Reports/Valuation.vue'),
    meta: {
      pageTitle: 'Değerleme',
      breadcrumb: [
        {
          text: 'Değerleme',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_REPORT',
      action: 'read',
    },
  },
  {
    path: '/dealers/reports/investor-summary/:id',
    name: 'DealerInvestorSummaryReport',
    component: () => import('@/views/Dealers/Reports/Investor_summary_report.vue'),
    meta: {
      pageTitle: 'Yatırımcı Özet Sayfası',
      breadcrumb: [
        {
          text: 'Yatırımcı Özet Sayfası',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_REPORT',
      action: 'read',
    },
  },
]
