import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    orders: [],
    ordersCount: 0,
    order: {},
    orderSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getOrders(state) {
      return state.orders
    },
    getOrdersCount(state) {
      return state.ordersCount
    },
    getOrder(state) {
      return state.order
    },
    getOrderSaveStatus(state) {
      return state.orderSave
    },
  },
  mutations: {
    ORDERS_LIST(state, data) {
      state.orders = data
    },
    ORDER_VIEW(state, data) {
      state.order = data
    },
    ORDERS_COUNT(state, data) {
      state.ordersCount = data
    },
    ORDER_SAVE(state, data) {
      state.orderSave = data
    },
  },
  actions: {
    ordersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/orders', data)
        .then(response => {
          commit('ORDERS_LIST', response.data.data)
          commit('ORDERS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    orderView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'orders.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/orders', data)
        .then(response => {
          commit('ORDER_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    orderSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/orders/saveData', data)
        .then(response => {
          commit('ORDER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
