import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    startupReport: {
      id: null,
      title: null,
      target_customers: null,
      website: null,
      email: null,
      linkedin: null,
      description: null,
      content: null,
      problems: null,
      solutions: null,
      revenue_model: null,
      market: null,
      demand_investment: null,
      rd_percent: null,
      sm_percent: null,
      ga_percent: null,
      cogs_percent: null,
      opex_percent: null,
      capex_percent: null,
      other_percent: null,
      seourl: null,
      activity_area: [],
      marketing_focus: [],
      teams: [],
      references: [],
      chartData: {
        labels: [],
        seriesData: [],
        colors: [],
      },
    },
    startupReports: [],
    startupReportError: null,
  },
  getters: {
    getStartupReport(state) {
      return state.startupReport
    },
    getStartupReports(state) {
      return state.startupReports
    },
    getStartupReportError(state) {
      return state.startupReportError
    },
  },
  mutations: {
    STARTUP_REPORT(state, data) {
      state.startupReport = data
    },
    STARTUP_REPORTS(state, data) {
      state.startupReports = data
    },
    STARTUP_REPORT_ERROR(state, data) {
      state.startupReportError = data
    },
  },
  actions: {
    startupReport({ commit }, id) {
      axiosIns
        .post('/dealers/reports/startups', { id_startups: id })
        .then(response => {
          commit('STARTUP_REPORT', response.data.data)
          commit('STARTUP_REPORT_ERROR', response.data.error)
        })
        .catch(error => console.error(error))
    },
    getReports({ commit }, id) {
      axiosIns
        .post('/dealers/reports/startups/getReports', { id })
        .then(response => {
          commit('STARTUP_REPORTS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
