import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    startupStatuses: [],
    startupStatusesCount: 0,
    startupStatus: {},
    startupStatusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getStartupStatuses(state) {
      return state.startupStatuses
    },
    getStartupStatusesCount(state) {
      return state.startupStatusesCount
    },
    getStartupStatus(state) {
      return state.startupStatus
    },
    getStartupStatusSaveStatus(state) {
      return state.startupStatusSave
    },
  },
  mutations: {
    STARTUP_STATUSES_LIST(state, data) {
      state.startupStatuses = data
    },
    STARTUP_STATUS_VIEW(state, data) {
      state.startupStatus = data
    },
    STARTUP_STATUSES_COUNT(state, data) {
      state.startupStatusesCount = data
    },
    STARTUP_STATUS_SAVE(state, data) {
      state.startupStatusSave = data
    },
  },
  actions: {
    startupStatusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/defines/startup_statuses', data)
        .then(response => {
          commit('STARTUP_STATUSES_LIST', response.data.data)
          commit('STARTUP_STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    startupStatusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'startup_statuses.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/defines/startup_statuses', data)
        .then(response => {
          commit('STARTUP_STATUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    startupStatusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/defines/startup_statuses/saveData', data)
        .then(response => {
          commit('STARTUP_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
