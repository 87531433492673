export default [
  {
    path: '/admin/paketler',
    name: 'Packages',
    component: () => import('@/views/Admin/Packages/Index.vue'),
    meta: {
      pageTitle: 'Paketler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PACKAGES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/paketler/ekle',
    name: 'PackagesAdd',
    component: () => import('@/views/Admin/Packages/Add.vue'),
    meta: {
      pageTitle: 'Paketler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/paketler',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_PACKAGES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/paketler/guncelle/:id',
    name: 'PackagesEdit',
    component: () => import('@/views/Admin/Packages/Edit.vue'),
    meta: {
      pageTitle: 'Paketler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/paketler',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PACKAGES_ALL',
      action: 'read',
    },
  },
]
