import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    user_types: [],
    user_typesCount: 0,
    user_type: {},
    user_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getUser_types(state) {
      return state.user_types
    },
    getUser_typesCount(state) {
      return state.user_typesCount
    },
    getUser_type(state) {
      return state.user_type
    },
    getUser_typeSaveStatus(state) {
      return state.user_typeSave
    },
  },
  mutations: {
    USER_TYPES_LIST(state, data) {
      state.user_types = data
    },
    USER_TYPE_VIEW(state, data) {
      state.user_type = data
    },
    USER_TYPES_COUNT(state, data) {
      state.user_typesCount = data
    },
    USER_TYPE_SAVE(state, data) {
      state.user_typeSave = data
    },
  },
  actions: {
    user_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/core/user_types', data)
        .then(response => {
          commit('USER_TYPES_LIST', response.data.data)
          commit('USER_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    user_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'user_types.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/core/user_types', data)
        .then(response => {
          commit('USER_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    user_typeSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/admin/core/user_types/saveData', data)
        .then(response => {
          commit('USER_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
