import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    profile: {},
    profileSaveStatus: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getProfile(state) {
      return state.profile
    },
    getProfileSaveStatus(state) {
      return state.profileSaveStatus
    },
  },
  mutations: {
    PROFILE_VIEW(state, data) {
      state.profile = data
    },
    PROFILE_SAVE(state, data) {
      state.profileSaveStatus = data
    },
  },
  actions: {
    profileView({ commit }) {
      axiosIns
        .post('/data/profile')
        .then(response => {
          commit('PROFILE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    profileSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/profile/saveData', data)
        .then(response => {
          commit('PROFILE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    // eslint-disable-next-line no-unused-vars
    selectedType({ commit }, id) {
      const data = JSON.stringify({ id_member_types: id })
      axiosIns
        .post('/data/profile/selectedType', data)
        .then(response => {
          localStorage.setItem('accessToken', response.data.data.accessToken)
          localStorage.setItem('downloadToken', response.data.data.downloadToken)
          localStorage.setItem('refreshToken', response.data.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(response.data.data.userData))
          // const userData = JSON.parse(localStorage.getItem('userData'))
          // userData.ability = response.data.data.userData.ability
          // commit('PROFILE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
