import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    newStartups: [],
    newStartupsCount: 0,
    newStartup: {
      title: null,
      company: null,
      target_customers: null,
      website: null,
      email: null,
      linkedin: null,
      upload_image: null,
      image: null,
      description: null,
      content: null,
      problems: null,
      solutions: null,
      revenue_model: null,
      competitive_advantages: null,
      market: null,
      award_supports: null,
      demand_investment: null,
      rd_percent: null,
      sm_percent: null,
      ga_percent: null,
      cogs_percent: null,
      opex_percent: null,
      capex_percent: null,
      other_percent: null,
      startup_teams: [
        {
          name: null,
          title: null,
          task: null,
        },
      ],
      startup_references: [{
        company: null,
        name: null,
      }],
      startup_activity_area: [],
      startup_marketing_focus: [],
    },
    newStartupSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getNewStartups(state) {
      return state.newStartups
    },
    getNewStartupsCount(state) {
      return state.newStartupsCount
    },
    getNewStartup(state) {
      return state.newStartup
    },
    getNewStartupSaveStatus(state) {
      return state.newStartupSave
    },
  },
  mutations: {
    NEW_STARTUPS_LIST(state, data) {
      state.newStartups = data
    },
    NEW_STARTUP_VIEW(state, data) {
      state.newStartup = data
    },
    NEW_STARTUPS_COUNT(state, data) {
      state.newStartupsCount = data
    },
    NEW_STARTUP_SAVE(state, data) {
      state.newStartupSave = data
    },
    RESET_NEW_STARTUP_VIEW(state) {
      state.newStartup = {
        title: null,
        company: null,
        target_customers: null,
        website: null,
        email: null,
        linkedin: null,
        upload_image: null,
        image: null,
        description: null,
        content: null,
        problems: null,
        solutions: null,
        revenue_model: null,
        competitive_advantages: null,
        market: null,
        award_supports: null,
        demand_investment: null,
        rd_percent: null,
        sm_percent: null,
        ga_percent: null,
        cogs_percent: null,
        opex_percent: null,
        capex_percent: null,
        other_percent: null,
        startup_teams: [
          {
            name: null,
            title: null,
            task: null,
          },
        ],
        startup_references: [{
          company: null,
          name: null,
        }],
        startup_activity_area: [],
        startup_marketing_focus: [],
      }
    },
  },
  actions: {
    newStartupsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/new_startups', data)
        .then(response => {
          commit('NEW_STARTUPS_LIST', response.data.data)
          commit('NEW_STARTUPS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    newStartupView({ commit }, id) {
      axiosIns
        .post('/data/new_startups', { id })
        .then(response => {
          commit('NEW_STARTUP_VIEW', response.data)
        })
        .catch(error => console.error(error))
    },
    newStartupSave({ commit }, postData) {
      const formData = new FormData()
      formData.append('data', JSON.stringify(postData))
      formData.append('upload_image', postData.upload_image)
      axiosIns
        .post('/data/new_startups/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('NEW_STARTUP_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
