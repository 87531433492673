export default [
  {
    path: '/profil',
    name: 'Profile',
    component: () => import('@/views/App/Profile/Index.vue'),
    meta: {
      pageTitle: 'Profil',
      breadcrumb: [
        {
          text: 'Profil',
          active: true,
        },
      ],
      resource: 'APP_PROFILE',
      action: 'read',
    },
  },
]
