import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    faqs: [],
  },
  getters: {
    getFaqs(state) {
      return state.faqs
    },
  },
  mutations: {
    FAQS_LIST(state, data) {
      state.faqs = data
    },
  },
  actions: {
    faqsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/faq', data)
        .then(response => {
          commit('FAQS_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
