import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dashboardData: [],
    dashboardDataCount: 0,
  },
  getters: {
    getDashboardData(state) {
      return state.dashboardData
    },
    getDashboardDataCount(state) {
      return state.dashboardDataCount
    },
  },
  mutations: {
    DASHBOARD_DATA_LIST(state, data) {
      state.dashboardData = data
    },
    DASHBOARD_DATA_COUNT(state, data) {
      state.dashboardDataCount = data
    },
  },
  actions: {
    dashboardDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/dashboard', data)
        .then(response => {
          commit('DASHBOARD_DATA_LIST', response.data.data)
          commit('DASHBOARD_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
  },
}
