import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataItem: {
      id: null,
      content: null,
      username: null,
      id_startups: null,
      created: null,
    },
    dataSaveStatus: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSaveStatus
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      if (data) {
        state.dataItem = data
      } else {
        state.dataItem = {
          id: null,
          content: null,
          username: null,
          id_startups: null,
          created: null,
        }
      }
    },
    SET_DATA_SAVE_STATUS(state, data) {
      state.dataSaveStatus = data
    },
  },
  actions: {
    getData({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'startup_comments.id_startups': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/startup_comments', data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    dataSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/startup_comments/saveData', data)
        .then(response => {
          commit('SET_DATA_SAVE_STATUS', response.data)
        })
        .catch(error => console.error(error))
    },
    dataRemove({ commit }, id) {
      axiosIns
        .post('/admin/startup_comments/deleteData', { id })
        .then(response => {
          commit('SET_DATA_SAVE_STATUS', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
