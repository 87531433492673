import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    financialPlan: [],
    fpResult: [],
    financialPlanSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getFinancialPlan(state) {
      return state.financialPlan
    },
    getFinancialPlanSaveStatus(state) {
      return state.financialPlanSave
    },
    getFpResult(state) {
      return state.fpResult
    },
  },
  mutations: {
    FINANCIAL_PLAN_LIST(state, data) {
      state.financialPlan = data
    },
    FINANCIAL_PLAN_SAVE(state, data) {
      state.financialPlanSave = data
    },
    SET_FP_RESULT(state, data) {
      state.fpResult = data
    },
    RESET_FP_DATA(state) {
      state.fpResult = []
      state.financialPlanSave = {
        message: null,
        status: null,
        id: null,
      }
    },
  },
  actions: {
    financialPlan({ commit }, params) {
      commit('RESET_FP_DATA')
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/financial_plan', data)
        .then(response => {
          commit('FINANCIAL_PLAN_LIST', response.data.data)
          commit('SET_FP_RESULT', response.data.fpResult)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/financial_plan/saveData', data)
        .then(response => {
          commit('FINANCIAL_PLAN_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    saveDraft({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/financial_plan/saveData?is_draft=1', data)
        .then(response => {
          commit('FINANCIAL_PLAN_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    setFpResult({ commit }, data) {
      commit('SET_FP_RESULT', data)
    },
  },
}
