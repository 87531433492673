import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    orderStatuses: [],
    orderStatusesCount: 0,
    orderStatus: {},
    orderStatusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getOrderStatuses(state) {
      return state.orderStatuses
    },
    getOrderStatusesCount(state) {
      return state.orderStatusesCount
    },
    getOrderStatus(state) {
      return state.orderStatus
    },
    getOrderStatusSaveStatus(state) {
      return state.orderStatusSave
    },
  },
  mutations: {
    ORDER_STATUSES_LIST(state, data) {
      state.orderStatuses = data
    },
    ORDER_STATUS_VIEW(state, data) {
      state.orderStatus = data
    },
    ORDER_STATUSES_COUNT(state, data) {
      state.orderStatusesCount = data
    },
    ORDER_STATUS_SAVE(state, data) {
      state.orderStatusSave = data
    },
  },
  actions: {
    orderStatusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/order_statuses', data)
        .then(response => {
          commit('ORDER_STATUSES_LIST', response.data.data)
          commit('ORDER_STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    orderStatusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'order_statuses.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/order_statuses', data)
        .then(response => {
          commit('ORDER_STATUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    orderStatusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/order_statuses/saveData', data)
        .then(response => {
          commit('ORDER_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
