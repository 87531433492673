import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import activityAreas from '@/store/admin/defines/activity_areas'
import marketingFocus from '@/store/admin/defines/marketing_focus'
import startupStatuses from '@/store/admin/defines/startup_statuses'
import startupConfigs from '@/store/admin/defines/startup_configs'
import startupReports from '@/store/admin/defines/startup_reports'
import faqCategories from '@/store/admin/faq_categories'
import bankAccounts from '@/store/admin/bank_accounts'
import orders from '@/store/admin/orders'
import terms from '@/store/admin/terms'
import packages from '@/store/admin/packages'
import orderStatuses from '@/store/admin/order_statuses'
import faq from '@/store/admin/faq'
import faqApp from '@/store/app/faq'
import userTypes from '@/store/core/user_types'
import notices from '@/store/app/notices'
import appActivityAreas from '@/store/app/defines/activity_areas'
import appMarketingFocus from '@/store/app/defines/marketing_focus'
import appStartupConfigs from '@/store/app/defines/startup_configs'
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
import adminDashboard from './admin/admin_dashboard'
import users from './core/users'
import smtpConfig from './core/smtpconfig'
import newStartups from './app/new_startups/new_startups'
import appDashboard from './app/app_dashboard'
import favorites from './app/interests/favorites'
import startups from './app/startups'
import profile from './app/profile'
import investors from './app/investors'
import marketAnalysis from './app/new_startups/market_analysis'
import businessModelAnalysis from './app/new_startups/business_model_analysis'
import technologyReadinessLevel from './app/new_startups/technology_readiness_level'
import financialPlan from './app/new_startups/financial_plan'
import sdg from './app/new_startups/sdg'
import memberStartups from './app/member_startups'
import portfolio from './app/portfolio'
import investorProfile from './app/investor_profile'
import startupApplies from './admin/startup_applies/startup_applies'
import preApplicationViewReport from './admin/startup_applies/pre_application_report'
import marketAnalysisReportAdmin from './admin/startup_applies/market_analysis'
import technologyReadinessReportAdmin from './admin/startup_applies/technology_readiness'
import businessModel from './admin/startup_applies/business_model'
import financialPlanning from './admin/startup_applies/financial_planning'
import sdgResult from './admin/startup_applies/sdg'
import startupComments from './admin/startup_comments'
import cart from './app/cart'
import appPackages from './app/packages'
import appOrders from './app/orders'
import appBankAccounts from './app/bank_accounts'
import adminInvestors from './admin/investors'
import adminDealers from './admin/dealers'
import adminDealerOrders from './admin/dealer_orders'
import adminDealerOrderResults from './admin/dealer_order_results'
/* Reports */
import startupReport from './app/reports/startup_report'
import marketAnalysisReport from './app/reports/market_analysis'
import businessModelAnalysisReport from './app/reports/business_model_analysis'
import technologyReadiness from './app/reports/technology_readiness'
import investorSummary from './app/reports/investor_summary'
import valuation from './app/reports/valuation'
import reportSdg from './app/reports/sdg'
/* Public Data */
import cities from './public/cities'
import appTerms from './public/terms'
import inviteCode from './public/invite_code'
/* Dealers */
import dealerPackages from './dealers/packages'
import dealerStartupApplies from './dealers/startup_applies/startup_applies'
import dealerPreApplicationViewReport from './dealers/startup_applies/pre_application_report'
import dealerMarketAnalysisReport from './dealers/startup_applies/market_analysis'
import dealerTechnologyReadinessReport from './dealers/startup_applies/technology_readiness'
import dealerBusinessModel from './dealers/startup_applies/business_model'
import dealerFinancialPlanning from './dealers/startup_applies/financial_planning'
import dealerSdgResult from './dealers/startup_applies/sdg'
import dealerStartupComments from './dealers/startup_applies/startup_comments'
import dealerAccount from './dealers/account'
import dealerOrders from './dealers/orders'
import dealerDashboard from './dealers/dashboard'
import dealerReportValuation from './dealers/reports/valuation'
import dealerReportStartupReport from './dealers/reports/startup_report'
import dealerReportBusinessModelAnalysis from './dealers/reports/business_model_analysis'
import dealerReportMarketAnalysis from './dealers/reports/market_analysis'
import dealerReportInvestorSummary from './dealers/reports/investor_summary'
import dealerReportTechnologyReadiness from './dealers/reports/technology_readiness'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users,
    smtpConfig,
    newStartups,
    marketAnalysis,
    businessModelAnalysis,
    technologyReadinessLevel,
    cities,
    terms,
    activityAreas,
    marketingFocus,
    startupStatuses,
    startupConfigs,
    startupReports,
    faqCategories,
    faq,
    faqApp,
    packages,
    orders,
    adminDashboard,
    orderStatuses,
    appDashboard,
    startups,
    favorites,
    profile,
    investors,
    memberStartups,
    financialPlan,
    sdg,
    startupReport,
    marketAnalysisReport,
    businessModelAnalysisReport,
    portfolio,
    investorProfile,
    userTypes,
    startupApplies,
    preApplicationViewReport,
    marketAnalysisReportAdmin,
    technologyReadinessReportAdmin,
    businessModel,
    financialPlanning,
    sdgResult,
    technologyReadiness,
    cart,
    appPackages,
    appOrders,
    notices,
    appActivityAreas,
    appMarketingFocus,
    appStartupConfigs,
    investorSummary,
    bankAccounts,
    appBankAccounts,
    valuation,
    reportSdg,
    appTerms,
    startupComments,
    adminInvestors,
    dealerPackages,
    dealerStartupApplies,
    dealerPreApplicationViewReport,
    dealerMarketAnalysisReport,
    dealerTechnologyReadinessReport,
    dealerBusinessModel,
    dealerFinancialPlanning,
    dealerSdgResult,
    dealerStartupComments,
    dealerAccount,
    dealerOrders,
    inviteCode,
    dealerDashboard,
    dealerReportValuation,
    dealerReportStartupReport,
    dealerReportBusinessModelAnalysis,
    dealerReportMarketAnalysis,
    dealerReportInvestorSummary,
    dealerReportTechnologyReadiness,
    adminDealers,
    adminDealerOrders,
    adminDealerOrderResults,
  },
  strict: process.env.DEV,
})
