import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketingFocuses: [],
    marketingFocusesCount: 0,
    marketingFocus: {},
    marketingFocusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMarketingFocuses(state) {
      return state.marketingFocuses
    },
    getMarketingFocusesCount(state) {
      return state.marketingFocusesCount
    },
    getMarketingFocus(state) {
      return state.marketingFocus
    },
    getMarketingFocusSaveStatus(state) {
      return state.marketingFocusSave
    },
  },
  mutations: {
    MARKETING_FOCUSES_LIST(state, data) {
      state.marketingFocuses = data
    },
    MARKETING_FOCUS_VIEW(state, data) {
      state.marketingFocus = data
    },
    MARKETING_FOCUSES_COUNT(state, data) {
      state.marketingFocusesCount = data
    },
    MARKETING_FOCUS_SAVE(state, data) {
      state.marketingFocusSave = data
    },
  },
  actions: {
    marketingFocusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/defines/marketing_focus', data)
        .then(response => {
          commit('MARKETING_FOCUSES_LIST', response.data.data)
          commit('MARKETING_FOCUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    marketingFocusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'marketing_focus.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/defines/marketing_focus', data)
        .then(response => {
          commit('MARKETING_FOCUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    marketingFocusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/defines/marketing_focus/saveData', data)
        .then(response => {
          commit('MARKETING_FOCUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
