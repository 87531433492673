import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    orders: [],
    order: {},
    orderSaveStatus: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getOrders(state) {
      return state.orders
    },
    getOrder(state) {
      return state.order
    },
    getOrderSaveStatus(state) {
      return state.orderSaveStatus
    },
  },
  mutations: {
    SET_ORDERS(state, data) {
      state.orders = data
    },
    SET_ORDER(state, data) {
      state.order = data
    },
    SET_ORDER_SAVE_STATUS(state, data) {
      state.orderSaveStatus = data
    },
  },
  actions: {
    ordersData({ commit }) {
      axiosIns
        .post('/data/orders')
        .then(response => {
          commit('SET_ORDER_SAVE_STATUS', {
            message: null,
            status: null,
            id: null,
          })
          commit('SET_ORDERS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    orderData({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'orders.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/orders', data)
        .then(response => {
          commit('SET_ORDER', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveOrderData({ commit }, formData) {
      const data = JSON.stringify(formData)
      axiosIns
        .post('/data/orders/saveData', data)
        .then(response => {
          commit('SET_ORDER_SAVE_STATUS', response.data)
        })
        .catch(error => console.error(error))
    },
    receiptUpload({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('receipt', postData.receipt)
      axiosIns
        .post('/data/orders/receiptUpload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_ORDER_SAVE_STATUS', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
