import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    faqCategories: [],
    faqCategoriesCount: 0,
    faqCategory: {},
    faqCategorySave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getFaqCategories(state) {
      return state.faqCategories
    },
    getFaqCategoriesCount(state) {
      return state.faqCategoriesCount
    },
    getFaqCategory(state) {
      return state.faqCategory
    },
    getFaqCategorySaveStatus(state) {
      return state.faqCategorySave
    },
  },
  mutations: {
    FAQ_CATEGORIES_LIST(state, data) {
      state.faqCategories = data
    },
    FAQ_CATEGORY_VIEW(state, data) {
      state.faqCategory = data
    },
    FAQ_CATEGORIES_COUNT(state, data) {
      state.faqCategoriesCount = data
    },
    FAQ_CATEGORY_SAVE(state, data) {
      state.faqCategorySave = data
    },
  },
  actions: {
    faqCategoriesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/faq_categories', data)
        .then(response => {
          commit('FAQ_CATEGORIES_LIST', response.data.data)
          commit('FAQ_CATEGORIES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    faqCategoryView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'faq_categories.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/faq_categories', data)
        .then(response => {
          commit('FAQ_CATEGORY_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    faqCategorySave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/faq_categories/saveData', data)
        .then(response => {
          commit('FAQ_CATEGORY_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
