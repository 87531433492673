import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketAnalysis: {},
  },
  getters: {
    getMarketAnalysisReport(state) {
      return state.marketAnalysis
    },
  },
  mutations: {
    MARKET_ANALYSIS_REPORT(state, data) {
      state.marketAnalysis = data
    },
  },
  actions: {
    marketAnalysis({ commit }, id) {
      axiosIns
        .post('/dealers/reports/market_analysis', { id_startups: id })
        .then(response => {
          commit('MARKET_ANALYSIS_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
