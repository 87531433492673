export default [
  {
    path: '/admin/tanimlamalar/girisimci-durumlari',
    name: 'StartupStatuses',
    component: () => import('@/views/Admin/Defines/Startup_statuses/Index.vue'),
    meta: {
      pageTitle: 'Girişimci Durumları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/tanimlamalar/girisimci-durumlari/ekle',
    name: 'StartupStatuseAdd',
    component: () => import('@/views/Admin/Defines/Startup_statuses/Add.vue'),
    meta: {
      pageTitle: 'Girişimci Durumları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tanimlamalar/girisimci-durumlari',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/tanimlamalar/girisimci-durumlari/guncelle/:id',
    name: 'StartupStatuseEdit',
    component: () => import('@/views/Admin/Defines/Startup_statuses/Edit.vue'),
    meta: {
      pageTitle: 'Girişimci Durumları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tanimlamalar/girisimci-durumlari',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
