import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    startups: [],
    startupsCount: 0,
    startup: {},
    startupFilterData: {
      id_marketing_focus: [],
      id_activity_areas: [],
    },
  },
  getters: {
    getStartups(state) {
      return state.startups
    },
    getStartupFilterData(state) {
      return state.startupFilterData
    },
    getStartupsCount(state) {
      return state.startupsCount
    },
    getStartup(state) {
      return state.startup
    },
  },
  mutations: {
    STARTUPS_LIST(state, data) {
      state.startups = data
    },
    STARTUP_VIEW(state, data) {
      state.startup = data
    },
    STARTUPS_COUNT(state, data) {
      state.startupsCount = data
    },
  },
  actions: {
    startupsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/startups', data)
        .then(response => {
          commit('STARTUPS_LIST', response.data.data)
          commit('STARTUPS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    startupView({ commit }, seourl) {
      axiosIns
        .post('/data/startups/view', { seourl })
        .then(response => {
          commit('STARTUP_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
