import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    memberStartups: [],
    memberStartupsCount: 0,
    memberStartup: {},
    memberStartupReports: [],
  },
  getters: {
    getMemberStartups(state) {
      return state.memberStartups
    },
    getMemberStartupsCount(state) {
      return state.memberStartupsCount
    },
    getMemberStartup(state) {
      return state.memberStartup
    },
    memberStartupReports(state) {
      return state.memberStartupReports
    },
  },
  mutations: {
    MEMBER_STARTUPS_LIST(state, data) {
      state.memberStartups = data
    },
    MEMBER_STARTUPS_COUNT(state, data) {
      state.memberStartupsCount = data
    },
    MEMBER_STARTUP_VIEW(state, data) {
      state.memberStartup = data
    },
    MEMBER_STARTUP_REPORTS(state, data) {
      state.memberStartupReports = data
    },
  },
  actions: {
    memberStartupsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/member_startups', data)
        .then(response => {
          commit('MEMBER_STARTUPS_LIST', response.data.data)
          commit('MEMBER_STARTUPS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    memberStartupView({ commit }, id) {
      axiosIns
        .post('/data/member_startups/view', { id })
        .then(response => {
          commit('MEMBER_STARTUP_VIEW', response.data.data)
          commit('MEMBER_STARTUP_REPORTS', response.data.reports)
        })
        .catch(error => console.error(error))
    },
  },
}
