export default [
  {
    path: '/takip-ettiklerim',
    name: 'Following',
    component: () => import('@/views/App/Interests/Following/Index.vue'),
    meta: {
      pageTitle: 'Takip Ettiklerim',
      breadcrumb: [
        {
          text: 'Takip Ettiklerim',
          active: true,
        },
      ],
      resource: 'FOLLOWING_LIST',
      action: 'read',
    },
  },
]
