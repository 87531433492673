import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataItem: {
      id: null,
      title: null,
      tax_no: null,
      tax_office: null,
      address: null,
      used_reports: null,
      usable_reports: null,
      usable_rate: null,
      usable_count: null,
      invite_code: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
  },
  actions: {
    getData({ commit }) {
      axiosIns
        .post('/dealers/account')
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, data) {
      axiosIns
        .post('/dealers/account/saveData', data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
