export default [
  {
    path: '/admin/bayi-siparis',
    name: 'DealerOrders',
    component: () => import('@/views/Admin/Dealer_orders/Index.vue'),
    meta: {
      pageTitle: 'Bayiler',
      breadcrumb: [
        {
          text: 'Siparişler',
          active: true,
        },
      ],
      resource: 'ADMIN_DEALERS',
      action: 'read',
    },
  },
  {
    path: '/admin/bayi-siparis/goruntule/:id',
    name: 'DealerOrderView',
    component: () => import('@/views/Admin/Dealer_orders/View.vue'),
    meta: {
      pageTitle: 'Bayiler',
      breadcrumb: [
        {
          text: 'Siparişler',
          to: '/admin/bayi-siparis',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEALERS',
      action: 'read',
    },
  },
]
