import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    preApplicationReport: {},
    startupReports: [],
    preApplicationReportSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getPreApplicationReport(state) {
      return state.preApplicationReport
    },
    startupReports(state) {
      return state.startupReports
    },
    getPreApplicationReportSaveStatus(state) {
      return state.preApplicationReportSave
    },
  },
  mutations: {
    PRE_APPLICATION_REPORT_VIEW(state, data) {
      state.preApplicationReport = data
    },
    MEMBER_STARTUP_REPORTS(state, data) {
      state.startupReports = data
    },
    PRE_APPLICATION_REPORT_SAVE(state, data) {
      state.preApplicationReportSave = data
    },
  },
  actions: {
    preApplicationReportView({ commit }, idStartups) {
      axiosIns
        .post('/dealers/startups/preApplication', { id_startups: idStartups })
        .then(response => {
          commit('PRE_APPLICATION_REPORT_VIEW', response.data.data)
          commit('MEMBER_STARTUP_REPORTS', response.data.startupReports)
        })
        .catch(error => console.error(error))
    },
    preApplicationReportSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/dealers/startups/saveData', data)
        .then(response => {
          commit('PRE_APPLICATION_REPORT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
