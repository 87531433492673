export default [
  {
    path: '/sikca-sorulan-sorular',
    name: 'FaqApp',
    component: () => import('@/views/App/Faq/Index.vue'),
    meta: {
      pageTitle: 'Sıkça Sorulan Sorular',
      breadcrumb: [
        {
          text: 'Sıkça Sorulan Sorular',
          active: true,
        },
      ],
      resource: 'APP_FAQ',
      action: 'read',
    },
  },
]
