import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    businessModel: [],
  },
  getters: {
    getBusinessModel(state) {
      return state.businessModel
    },
  },
  mutations: {
    BUSINESS_MODEL(state, data) {
      state.businessModel = data
    },
  },
  actions: {
    businessModel({ commit }, idStartups) {
      axiosIns
        .post('/dealers/startups/businessModel', { id_startups: idStartups })
        .then(response => {
          commit('BUSINESS_MODEL', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
