export default [
  {
    path: '/admin/tanimlamalar/sabit-icerikler',
    name: 'Terms',
    component: () => import('@/views/Admin/Defines/Terms/Index.vue'),
    meta: {
      pageTitle: 'Sabit İçerikler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/tanimlamalar/sabit-icerikler/ekle',
    name: 'TermsAdd',
    component: () => import('@/views/Admin/Defines/Terms/Add.vue'),
    meta: {
      pageTitle: 'Sabit İçerikler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tanimlamalar/sabit-icerikler',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/tanimlamalar/sabit-icerikler/guncelle/:id',
    name: 'TermsEdit',
    component: () => import('@/views/Admin/Defines/Terms/Edit.vue'),
    meta: {
      pageTitle: 'Sabit İçerikler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tanimlamalar/sabit-icerikler',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
