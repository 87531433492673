import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    favorites: [],
    favoritesCount: 0,
    favoriteToggleStatus: {
      message: null,
      status: null,
    },
  },
  getters: {
    getFavorites(state) {
      return state.favorites
    },
    getFavoritesCount(state) {
      return state.favoritesCount
    },
    getFavoritesToggleStatus(state) {
      return state.favoriteToggleStatus
    },
  },
  mutations: {
    FAVORITES_LIST(state, data) {
      state.favorites = data
    },
    FAVORITES_COUNT(state, data) {
      state.favoritesCount = data
    },
    FAVORITE_TOGGLE(state, data) {
      state.favoriteToggleStatus = data
    },
  },
  actions: {
    favoritesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/member_favorites', data)
        .then(response => {
          commit('FAVORITES_LIST', response.data.data)
          commit('FAVORITES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    favoritesToggle({ commit }, id) {
      axiosIns
        .post('/data/member_favorites/toggleFavorite', { id })
        .then(response => {
          commit('FAVORITE_TOGGLE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
