import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    investors: [],
    investorsCount: 0,
  },
  getters: {
    getInvestors(state) {
      return state.investors
    },
    getInvestorsCount(state) {
      return state.investorsCount
    },
  },
  mutations: {
    INVESTORS_LIST(state, data) {
      state.investors = data
    },
    INVESTORS_COUNT(state, data) {
      state.investorsCount = data
    },
  },
  actions: {
    investorsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/investors', data)
        .then(response => {
          commit('INVESTORS_LIST', response.data.data)
          commit('INVESTORS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
  },
}
