import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketAnalysis: [],
    maResult: [],
    marketAnalysisSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMarketAnalysis(state) {
      return state.marketAnalysis
    },
    getMarketAnalysisSaveStatus(state) {
      return state.marketAnalysisSave
    },
    getMaResult(state) {
      return state.maResult
    },
  },
  mutations: {
    MARKET_ANALYSIS_LIST(state, data) {
      state.marketAnalysis = data
    },
    MARKET_ANALYSIS_SAVE(state, data) {
      state.marketAnalysisSave = data
    },
    SET_MA_RESULT(state, data) {
      state.maResult = data
    },
    RESET_MA_DATA(state) {
      state.maResult = []
      state.marketAnalysisSave = {
        message: null,
        status: null,
        id: null,
      }
    },
  },
  actions: {
    marketAnalysis({ commit }, params) {
      commit('RESET_MA_DATA')
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/market_analysis', data)
        .then(response => {
          commit('MARKET_ANALYSIS_LIST', response.data.data)
          commit('SET_MA_RESULT', response.data.maResult)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/market_analysis/saveData', data)
        .then(response => {
          commit('MARKET_ANALYSIS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    saveDraft({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/market_analysis/saveData?is_draft=1', data)
        .then(response => {
          commit('MARKET_ANALYSIS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    setMaResult({ commit }, data) {
      commit('SET_MA_RESULT', data)
    },
  },
}
