import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    activityAreas: [],
    activityAreasCount: 0,
    activityArea: {},
    activityAreaSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getActivityAreas(state) {
      return state.activityAreas
    },
    getActivityAreasCount(state) {
      return state.activityAreasCount
    },
    getActivityArea(state) {
      return state.activityArea
    },
    getActivityAreaSaveStatus(state) {
      return state.activityAreaSave
    },
  },
  mutations: {
    ACTIVITY_AREAS_LIST(state, data) {
      state.activityAreas = data
    },
    ACTIVITY_AREA_VIEW(state, data) {
      state.activityArea = data
    },
    ACTIVITY_AREAS_COUNT(state, data) {
      state.activityAreasCount = data
    },
    ACTIVITY_AREA_SAVE(state, data) {
      state.activityAreaSave = data
    },
  },
  actions: {
    activityAreasList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/defines/activity_areas', data)
        .then(response => {
          commit('ACTIVITY_AREAS_LIST', response.data.data)
          commit('ACTIVITY_AREAS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    activityAreaView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'activity_areas.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/defines/activity_areas', data)
        .then(response => {
          commit('ACTIVITY_AREA_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    activityAreaSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/defines/activity_areas/saveData', data)
        .then(response => {
          commit('ACTIVITY_AREA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
