export default [
  {
    path: '/admin/siparisler',
    name: 'Orders',
    component: () => import('@/views/Admin/Orders/Index.vue'),
    meta: {
      pageTitle: 'Siparişler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_ORDERS_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/siparisler/bekleyen-siparisler',
    name: 'WaitingOrders',
    component: () => import('@/views/Admin/Orders/Waiting_orders.vue'),
    meta: {
      pageTitle: 'Bekleyen Siparişler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_ORDERS_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/siparisler/tamamlanan-siparisler',
    name: 'CompletedOrders',
    component: () => import('@/views/Admin/Orders/Completed_orders.vue'),
    meta: {
      pageTitle: 'Tamamlanan Siparişler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_ORDERS_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/siparisler/iptal-siparisler',
    name: 'CanceledOrders',
    component: () => import('@/views/Admin/Orders/Canceled_orders.vue'),
    meta: {
      pageTitle: 'İptal Siparişler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_ORDERS_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/siparisler/goruntule/:id',
    name: 'OrderView',
    component: () => import('@/views/Admin/Orders/View.vue'),
    meta: {
      pageTitle: 'Siparişler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/siparisler',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_ORDERS_ALL',
      action: 'read',
    },
  },
]
