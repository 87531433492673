import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    technologyReadinessLevel: [],
    trlResult: [],
    technologyReadinessLevelSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getTechnologyReadinessLevel(state) {
      return state.technologyReadinessLevel
    },
    getTechnologyReadinessLevelSaveStatus(state) {
      return state.technologyReadinessLevelSave
    },
    getTrlResult(state) {
      return state.trlResult
    },
  },
  mutations: {
    TECHNOLOGY_READINESS_LEVEL_LIST(state, data) {
      state.technologyReadinessLevel = data
    },
    TECHNOLOGY_READINESS_LEVEL_SAVE(state, data) {
      state.technologyReadinessLevelSave = data
    },
    SET_TRL_RESULT(state, data) {
      state.trlResult = data
    },
    RESET_TRL_DATA(state) {
      state.trlResult = []
      state.technologyReadinessLevel = []
      state.technologyReadinessLevelSave = {
        message: null,
        status: null,
        id: null,
      }
    },
  },
  actions: {
    technologyReadinessLevel({ commit }, params) {
      commit('RESET_TRL_DATA')
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/technology_readiness_level', data)
        .then(response => {
          commit('TECHNOLOGY_READINESS_LEVEL_LIST', response.data.data)
          commit('SET_TRL_RESULT', response.data.trlResult)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/technology_readiness_level/saveData', data)
        .then(response => {
          commit('TECHNOLOGY_READINESS_LEVEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    saveDraft({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/technology_readiness_level/saveData?is_draft=1', data)
        .then(response => {
          commit('TECHNOLOGY_READINESS_LEVEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    setTrlResult({ commit }, data) {
      commit('SET_TRL_RESULT', data)
    },
  },
}
