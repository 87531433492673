import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketingFocuses: [],
    marketingFocusesCount: 0,
    marketingFocus: {},
  },
  getters: {
    getMarketingFocuses(state) {
      return state.marketingFocuses
    },
    getMarketingFocusesCount(state) {
      return state.marketingFocusesCount
    },
    getMarketingFocus(state) {
      return state.marketingFocus
    },
  },
  mutations: {
    MARKETING_FOCUSES_LIST(state, data) {
      state.marketingFocuses = data
    },
    MARKETING_FOCUS_VIEW(state, data) {
      state.marketingFocus = data
    },
    MARKETING_FOCUSES_COUNT(state, data) {
      state.marketingFocusesCount = data
    },
  },
  actions: {
    marketingFocusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/marketing_focus', data)
        .then(response => {
          commit('MARKETING_FOCUSES_LIST', response.data.data)
          commit('MARKETING_FOCUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    marketingFocusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'marketing_focus.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/marketing_focus', data)
        .then(response => {
          commit('MARKETING_FOCUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
