import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataItem: {
      id: null,
      title: null,
      price: null,
      taxes: null,
      reports: null,
      register: null,
      bma_report: null,
      ma_report: null,
      ca_report: null,
      trl_report: null,
      fp_report: null,
      sdg_report: null,
      mentors: null,
      ecosystem: null,
      gv_contest: null,
      jury: null,
    },
    paymentForm: {
      name_holder: null,
      card_number: null,
      card_date: null,
      card_cvv: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataItem(state) {
      return state.dataItem
    },
    paymentForm(state) {
      return state.paymentForm
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
  },
  actions: {
    getDataList({ commit }) {
      axiosIns
        .post('/dealers/dealer_packages')
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getData({ commit }, params) {
      axiosIns
        .post('/dealers/dealer_packages', params)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
