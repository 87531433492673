export default [
  {
    path: '/dealers/account',
    name: 'DealerAccount',
    component: () => import('@/views/Dealers/Account/Index.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Hesabım',
          active: true,
        },
      ],
      resource: 'DEALER_ACCOUNT',
      action: 'read',
    },
  },
  {
    path: '/dealers/account/order/:id',
    name: 'DealerAccountOrders',
    component: () => import('@/views/Dealers/Account/Order.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Hesabım',
          to: '/dealers/account',
        },
        {
          text: 'Sipariş Detay',
          active: true,
        },
      ],
      resource: 'DEALER_ACCOUNT',
      action: 'read',
    },
  },
]
