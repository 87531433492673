import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    faqs: [],
    faqCount: 0,
    faq: {},
    faqSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getFaqs(state) {
      return state.faqs
    },
    getFaqsCount(state) {
      return state.faqCount
    },
    getFaq(state) {
      return state.faq
    },
    getFaqSaveStatus(state) {
      return state.faqSave
    },
  },
  mutations: {
    FAQS_LIST(state, data) {
      state.faqs = data
    },
    FAQ_VIEW(state, data) {
      state.faq = data
    },
    FAQS_COUNT(state, data) {
      state.faqCount = data
    },
    FAQ_SAVE(state, data) {
      state.faqSave = data
    },
  },
  actions: {
    faqsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/faq', data)
        .then(response => {
          commit('FAQS_LIST', response.data.data)
          commit('FAQS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    faqView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'faq.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/faq', data)
        .then(response => {
          commit('FAQ_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    faqSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/faq/saveData', data)
        .then(response => {
          commit('FAQ_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
