import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dealerData: {},
  },
  getters: {
    dealerData(state) {
      return state.dealerData
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.dealerData = data
    },
  },
  actions: {
    getInviteCode({ commit }, params) {
      return axiosIns
        .get('/public/invite_code', { params })
        .then(response => {
          commit('SET_DATA', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
