import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    technologyReadinessList: [],
    technologyReadinessScoreAVG: 0,
    technologyReadiness: {},
  },
  getters: {
    getTechnologyReadinessList(state) {
      return state.technologyReadinessList
    },
    getTechnologyReadinessScore(state) {
      return state.technologyReadinessScoreAVG
    },
  },
  mutations: {
    TECHNOLOGY_READINESS_LEVEL_LIST(state, data) {
      state.technologyReadinessList = data
    },
    TECHNOLOGY_READINESS_LEVEL_SCORE(state, data) {
      state.technologyReadinessScoreAVG = data
    },
  },
  actions: {
    technologyReadiness({ commit }, id) {
      axiosIns
        .post('/reports/technology_readiness_level', { id_startups: id })
        .then(response => {
          commit('TECHNOLOGY_READINESS_LEVEL_LIST', response.data.data)
          commit('TECHNOLOGY_READINESS_LEVEL_SCORE', response.data.totalScore)
        })
        .catch(error => console.error(error))
    },
  },
}
