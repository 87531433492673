import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    startup: {
      id: null,
      title: null,
      avatar: null,
      description: null,
      content: null,
      website: null,
      email: null,
      linkedin: null,
      marketing_focus: null,
      avatar_text: null,
      id_startup_statuses: null,
    },
    startupApplies: [],
    startupAppliesCount: 0,
    startupReports: [],
    startupApply: {},
    startupApplySave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getStartup(state) {
      return state.startup
    },
    getStartupApplies(state) {
      return state.startupApplies
    },
    getStartupAppliesCount(state) {
      return state.startupAppliesCount
    },
    startupReports(state) {
      return state.startupReports
    },
    getStartupApply(state) {
      return state.startupApply
    },
    getStartupApplySaveStatus(state) {
      return state.startupApplySave
    },
  },
  mutations: {
    STARTUP_DATA(state, data) {
      state.startup = data
    },
    STARTUP_APPLIES_LIST(state, data) {
      state.startupApplies = data
    },
    STARTUP_APPLY_VIEW(state, data) {
      state.startupApply = data
    },
    STARTUP_APPLIES_COUNT(state, data) {
      state.startupAppliesCount = data
    },
    STARTUP_REPORTS(state, data) {
      state.startupReports = data
    },
    STARTUP_APPLY_SAVE(state, data) {
      state.startupApplySave = data
    },
  },
  actions: {
    startupAppliesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dealers/startups', data)
        .then(response => {
          commit('STARTUP_APPLIES_LIST', response.data.data)
          commit('STARTUP_APPLIES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getStartupReports({ commit }, id) {
      axiosIns
        .post('/dealers/startups/view', { id })
        .then(response => {
          commit('STARTUP_REPORTS', response.data.data)
          commit('STARTUP_DATA', response.data.startup)
        })
        .catch(error => console.error(error))
    },
    startupApplyView({ commit }, idStartups) {
      axiosIns
        .post('/dealers/startups/preApplication', { id_startups: idStartups })
        .then(response => {
          commit('STARTUP_APPLY_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    startupApplySave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/dealers/startups/saveData', data)
        .then(response => {
          commit('STARTUP_APPLY_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
