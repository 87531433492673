import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    notices: [],
    noticesCount: null,
    noticeSaveStatus: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getNotices(state) {
      return state.notices
    },
    getNoticesCount(state) {
      return state.noticesCount
    },
    getNoticeSaveStatus(state) {
      return state.noticeSaveStatus
    },
  },
  mutations: {
    SET_NOTICES(state, data) {
      state.notices = data
    },
    SET_NOTICES_COUNT(state, data) {
      state.noticesCount = data
    },
    SET_NOTICE_SAVE(state, data) {
      state.noticeSaveStatus = data
    },
  },
  actions: {
    notices({ commit }) {
      axiosIns
        .post('/data/notices')
        .then(response => {
          commit('SET_NOTICES', response.data.data)
          commit('SET_NOTICES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    readNotice({ commit }, id) {
      axiosIns
        .post('/data/notices/readNotice', { id })
        .then(response => {
          commit('SET_NOTICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
