import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    businessModelAnalysis: [],
    bmaResult: [],
    businessModelAnalysisSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getBusinessModelAnalysis(state) {
      return state.businessModelAnalysis
    },
    getBusinessModelAnalysisSaveStatus(state) {
      return state.businessModelAnalysisSave
    },
    getBmaResult(state) {
      return state.bmaResult
    },
  },
  mutations: {
    BUSINESS_MODEL_ANALYSIS_LIST(state, data) {
      state.businessModelAnalysis = data
    },
    BUSINESS_MODEL_ANALYSIS_SAVE(state, data) {
      state.businessModelAnalysisSave = data
    },
    SET_BMA_RESULT(state, data) {
      state.bmaResult = data
    },
    RESET_BMA_DATA(state) {
      state.bmaResult = []
      state.businessModelAnalysisSave = {
        message: null,
        status: null,
        id: null,
      }
    },
  },
  actions: {
    businessModelAnalysis({ commit }, params) {
      commit('RESET_BMA_DATA')
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/business_model_analysis', data)
        .then(response => {
          commit('BUSINESS_MODEL_ANALYSIS_LIST', response.data.data)
          commit('SET_BMA_RESULT', response.data.bmaResult)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/business_model_analysis/saveData', data)
        .then(response => {
          commit('BUSINESS_MODEL_ANALYSIS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    saveDraft({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/business_model_analysis/saveData?is_draft=1', data)
        .then(response => {
          commit('BUSINESS_MODEL_ANALYSIS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    setBmaResult({ commit }, data) {
      commit('SET_BMA_RESULT', data)
    },
  },
}
