import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    cities: [],
    citiesCount: 0,
  },
  getters: {
    getCities(state) {
      return state.cities
    },
    getCitiesCount(state) {
      return state.citiesCount
    },
  },
  mutations: {
    CITIES_LIST(state, data) {
      state.cities = data
    },
    CITIES_COUNT(state, data) {
      state.citiesCount = data
    },
  },
  actions: {
    citiesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/public/cities', data)
        .then(response => {
          commit('CITIES_LIST', response.data.data)
          commit('CITIES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
  },
}
