export default [
  {
    path: '/admin/kullanicilar',
    name: 'Users',
    component: () => import('@/views/Admin/Users/Index.vue'),
    meta: {
      pageTitle: 'Kullanıcılar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_USERS',
      action: 'read',
    },
  },
  {
    path: '/admin/kullanicilar/ekle',
    name: 'UsersAdd',
    component: () => import('@/views/Admin/Users/Add.vue'),
    meta: {
      pageTitle: 'Kullanıcılar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/kullanicilar',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_USERS',
      action: 'read',
    },
  },
  {
    path: '/admin/kullanicilar/guncelle/:id',
    name: 'UsersEdit',
    component: () => import('@/views/Admin/Users/Edit.vue'),
    meta: {
      pageTitle: 'Kullanıcılar',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          to: '/admin/kullanicilar',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_USERS',
      action: 'read',
    },
  },
]
