import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    investorSummary: {},
  },
  getters: {
    getInvestorSummary(state) {
      return state.investorSummary
    },
  },
  mutations: {
    SET_INVESTOR_SUMMARY(state, data) {
      state.investorSummary = data
    },
  },
  actions: {
    investorSummary({ commit }, id) {
      axiosIns
        .post('/dealers/reports/investor_summary', { id_startups: id })
        .then(response => {
          commit('SET_INVESTOR_SUMMARY', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
