export default [
  {
    path: '/siparislerim',
    name: 'AppOrders',
    component: () => import('@/views/App/Orders/Index.vue'),
    meta: {
      pageTitle: 'Siparişlerim',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'APP_ORDERS',
      action: 'read',
    },
  },
  {
    path: '/siparislerim/sepet',
    name: 'OrderCheckout',
    component: () => import('@/views/App/Orders/Checkout/Index.vue'),
    meta: {
      pageTitle: 'Siparişlerim',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/siparislerim',
          active: false,
        },
        {
          text: 'Sepet',
          active: true,
        },
      ],
      resource: 'APP_ORDERS',
      action: 'read',
    },
  },
  {
    path: '/siparislerim/sepet/odeme',
    name: 'OrderPayment',
    component: () => import('@/views/App/Orders/Checkout/Payment.vue'),
    meta: {
      pageTitle: 'Siparişlerim',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/siparislerim',
          active: false,
        },
        {
          text: 'Sepet',
          to: '/siparislerim/sepet',
          active: false,
        },
        {
          text: 'Ödeme',
          active: true,
        },
      ],
      resource: 'APP_ORDERS',
      action: 'read',
    },
  },
  {
    path: '/siparislerim/detay/:id',
    name: 'OrderDetail',
    component: () => import('@/views/App/Orders/View.vue'),
    meta: {
      pageTitle: 'Siparişlerim',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/siparislerim',
          active: false,
        },
        {
          text: 'Sipariş Detay',
          active: true,
        },
      ],
      resource: 'APP_ORDERS',
      action: 'read',
    },
  },
]
