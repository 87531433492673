export default [
  {
    path: '/admin/kullanici-tipleri',
    name: 'UserTypes',
    component: () => import('@/views/Admin/User_types/Index.vue'),
    meta: {
      pageTitle: 'Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_USER_TYPES',
      action: 'read',
    },
  },
  {
    path: '/admin/kullanici-tipleri/ekle',
    name: 'UserTypesAdd',
    component: () => import('@/views/Admin/User_types/Add.vue'),
    meta: {
      pageTitle: 'Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/kullanici-tipleri',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ADMIN_USER_TYPES',
      action: 'read',
    },
  },
  {
    path: '/admin/kullanici-tipleri/guncelle/:id',
    name: 'UserTypesEdit',
    component: () => import('@/views/Admin/User_types/Edit.vue'),
    meta: {
      pageTitle: 'Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Kullanıcı Tipleri',
          to: '/admin/kullanici-tipleri',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_USER_TYPES',
      action: 'read',
    },
  },
]
