export default [
  {
    path: '/dealers/startup-applications',
    name: 'DealerStartupApps',
    component: () => import('@/views/Dealers/Startup_applies/Index.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/dealers/startup-applications/view/:id',
    name: 'DealerStartupAppView',
    component: () => import('@/views/Dealers/Startup_applies/View.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/dealers/startup-applications',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/dealers/startup-applications/pre-application/:id',
    name: 'DealerStartupPreApp',
    component: () => import('@/views/Dealers/Startup_applies/PreApplicationView.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/dealers/startup-applications',
          active: false,
        },
        {
          text: 'Ön Başvuru',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/dealers/startup-applications/market-analysis/:id',
    name: 'DealerStartupMarketAnalysis',
    component: () => import('@/views/Dealers/Startup_applies/MarketAnalysisView.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/dealers/startup-applications',
          active: false,
        },
        {
          text: 'Pazar Analizi',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/dealers/startup-applications/business-model/:id',
    name: 'DealerStartupBusinessModel',
    component: () => import('@/views/Dealers/Startup_applies/BusinessModelView.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/dealers/startup-applications',
          active: false,
        },
        {
          text: 'İş Modeli Analizi',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/dealers/startup-applications/technology-readiness/:id',
    name: 'DealerStartupTechnologyReadiness',
    component: () => import('@/views/Dealers/Startup_applies/TechnologyReadiness.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/dealers/startup-applications',
          active: false,
        },
        {
          text: 'Teknoloji Hazırlık Seviyesi',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/dealers/startup-applications/financial-planning/:id',
    name: 'DealerStartupFinancialPlanning',
    component: () => import('@/views/Dealers/Startup_applies/FinancialPlanning.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/dealers/startup-applications',
          active: false,
        },
        {
          text: 'Finansal Planlama',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
  {
    path: '/dealers/startup-applications/sgd/:id',
    name: 'DealerStartupSgd',
    component: () => import('@/views/Dealers/Startup_applies/Sgd.vue'),
    meta: {
      pageTitle: null,
      breadcrumb: [
        {
          text: 'Girişim Başvuruları',
          to: '/dealers/startup-applications',
          active: false,
        },
        {
          text: 'Etki Analizi',
          active: true,
        },
      ],
      resource: 'DEALER_STARTUP_APPLICATIONS',
      action: 'read',
    },
  },
]
