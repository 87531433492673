import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    terms: [],
    termsCount: 0,
    term: {},
    termSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getTerms(state) {
      return state.terms
    },
    getTermsCount(state) {
      return state.termsCount
    },
    getTerm(state) {
      return state.term
    },
    getTermSaveStatus(state) {
      return state.termSave
    },
  },
  mutations: {
    TERMS_LIST(state, data) {
      state.terms = data
    },
    TERM_VIEW(state, data) {
      state.term = data
    },
    TERMS_COUNT(state, data) {
      state.termsCount = data
    },
    TERM_SAVE(state, data) {
      state.termSave = data
    },
  },
  actions: {
    termsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/admin/terms', data)
        .then(response => {
          commit('TERMS_LIST', response.data.data)
          commit('TERMS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    termView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'terms.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/admin/terms', data)
        .then(response => {
          commit('TERM_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    termSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/admin/terms/saveData', data)
        .then(response => {
          commit('TERM_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
