export default [
  {
    path: '/girisim-basvurularim',
    name: 'StartupApplies',
    component: () => import('@/views/App/Interests/Requests/Index.vue'),
    meta: {
      pageTitle: 'Benim Sayfam',
      breadcrumb: [
        {
          text: 'Girişim Başvurularım',
          active: true,
        },
      ],
      resource: 'APP_REQUESTS',
      action: 'read',
    },
  },
  {
    path: '/girisim-basvurularim/goruntule/:id',
    name: 'StartupAppView',
    component: () => import('@/views/App/Interests/Requests/View.vue'),
    meta: {
      pageTitle: 'Benim Sayfam',
      breadcrumb: [
        {
          text: 'Girişim Başvurularım',
          to: '/girisim-basvurularim',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'APP_REQUESTS',
      action: 'read',
    },
  },
]
