import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    packages: [],
  },
  getters: {
    getPackages(state) {
      return state.packages
    },
  },
  mutations: {
    SET_PACKAGES(state, data) {
      state.packages = data
    },
  },
  actions: {
    packagesData({ commit }) {
      axiosIns
        .post('/data/packages')
        .then(response => {
          commit('SET_PACKAGES', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
