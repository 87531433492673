import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    technologyReadiness: [],
  },
  getters: {
    getTechnologyReadiness(state) {
      return state.technologyReadiness
    },
  },
  mutations: {
    TECHNOLOGY_READINESS(state, data) {
      state.technologyReadiness = data
    },
  },
  actions: {
    technologyReadiness({ commit }, idStartups) {
      axiosIns
        .post('/dealers/startups/technologyReadiness', { id_startups: idStartups })
        .then(response => {
          commit('TECHNOLOGY_READINESS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
