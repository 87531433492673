import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    financialPlanning: [],
  },
  getters: {
    getFinancialPlanning(state) {
      return state.financialPlanning
    },
  },
  mutations: {
    FINANCIAL_PLANING(state, data) {
      state.financialPlanning = data
    },
  },
  actions: {
    financialPlanning({ commit }, idStartups) {
      axiosIns
        .post('/dealers/startups/financialPlanning', { id_startups: idStartups })
        .then(response => {
          commit('FINANCIAL_PLANING', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
