export default [
  {
    path: '/girisimler',
    name: 'Startups',
    component: () => import('@/views/App/Startups/Index.vue'),
    meta: {
      pageTitle: 'Girişimler',
      breadcrumb: [
        {
          text: 'Keşfet',
          active: true,
        },
      ],
      resource: 'APP_STARTUPS',
      action: 'read',
    },
  },
  {
    path: '/girisimler/:seourl',
    name: 'StartupView',
    component: () => import('@/views/App/Startups/View.vue'),
    meta: {
      pageTitle: 'Girişimler',
      breadcrumb: [
        {
          text: 'Keşfet',
          to: '/girisimler',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'APP_STARTUPS',
      action: 'read',
    },
  },
]
