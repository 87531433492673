export default [
  {
    path: '/girisimci-basvurusu',
    name: 'StartupNew',
    component: () => import('@/views/App/NewStartups/New.vue'),
    meta: {
      pageTitle: 'Girişimci Başvurusu',
      breadcrumb: [
        {
          text: 'Başvuru Formu',
          active: true,
        },
      ],
      resource: 'APP_NEW_STARTUPS',
      action: 'read',
    },
  },
  {
    path: '/girisimci-basvurusu/on-basvuru/:id',
    name: 'PreAppForm',
    component: () => import('@/views/App/NewStartups/New.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Girişim Başvurularım',
          to: '/girisim-basvurularim',
          active: false,
        },
        {
          text: 'Ön Başvuru',
          active: true,
        },
      ],
      resource: 'APP_NEW_STARTUPS',
      action: 'read',
    },
  },
  {
    path: '/girisimci-basvurusu/pazar-analizi/:id',
    name: 'MaForm',
    component: () => import('@/views/App/NewStartups/MarketAnalysis.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Girişim Başvurularım',
          to: '/girisim-basvurularim',
          active: false,
        },
        {
          text: 'Pazar Analizi',
          active: true,
        },
      ],
      resource: 'APP_NEW_STARTUPS',
      action: 'read',
    },
  },
  {
    path: '/girisimci-basvurusu/is-modeli-analizi/:id',
    name: 'BusinessModelAnalysisForm',
    component: () => import('@/views/App/NewStartups/BusinessModelAnalysis.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Girişim Başvurularım',
          to: '/girisim-basvurularim',
          active: false,
        },
        {
          text: 'İş Modeli Analizi',
          active: true,
        },
      ],
      resource: 'APP_NEW_STARTUPS',
      action: 'read',
    },
  },
  {
    path: '/girisimci-basvurusu/teknoloji-hazirlik-seviyesi/:id',
    name: 'TechnologyReadinessLevelForm',
    component: () => import('@/views/App/NewStartups/TechnologyReadinessLevel.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Girişim Başvurularım',
          to: '/girisim-basvurularim',
          active: false,
        },
        {
          text: 'Teknoloji Hazırlık Seviyesi',
          active: true,
        },
      ],
      resource: 'APP_NEW_STARTUPS',
      action: 'read',
    },
  },
  {
    path: '/girisimci-basvurusu/finansal-planlama/:id',
    name: 'FinancialPlanningForm',
    component: () => import('@/views/App/NewStartups/FinancialPlanning.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Girişim Başvurularım',
          to: '/girisim-basvurularim',
          active: false,
        },
        {
          text: 'Finansal Planlama',
          active: true,
        },
      ],
      resource: 'APP_NEW_STARTUPS',
      action: 'read',
    },
  },
  {
    path: '/girisimci-basvurusu/etki-analizi/:id',
    name: 'SDG',
    component: () => import('@/views/App/NewStartups/Sdg.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Girişim Başvurularım',
          to: '/girisim-basvurularim',
          active: false,
        },
        {
          text: 'Etki Analizi',
          active: true,
        },
      ],
      resource: 'APP_NEW_STARTUPS',
      action: 'read',
    },
  },
]
