import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    cart: {
      items: [],
      count: 0,
      total: 0,
      taxTotal: 0,
    },
  },
  getters: {
    getCart(state) {
      return state.cart
    },
  },
  mutations: {
    SET_CART(state, data) {
      state.cart = data
    },
  },
  actions: {
    cartData({ commit }) {
      axiosIns
        .post('/data/cart')
        .then(response => {
          commit('SET_CART', response.data.data)
        })
        .catch(error => console.error(error))
    },
    cartAddItem({ commit }, data) {
      axiosIns
        .post('/data/cart/addItem', data)
        .then(response => {
          commit('SET_CART', response.data.data)
        })
        .catch(error => console.error(error))
    },
    cartRemoveItem({ commit }, id) {
      axiosIns
        .post('/data/cart/removeItem', { id })
        .then(response => {
          commit('SET_CART', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
